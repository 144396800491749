import React from "react";
import Helmet from "react-helmet";
import Footer from "./footer/Footer";
import Navbar from "./header/Navbar";

const Layout = ({ children, title }) => {
  return (
    <>
      <Helmet>
        <title>{title ? title : "Evil Kongs | Staking"}</title>
      </Helmet>
      <div className="main-wrapper">
        <Navbar />
        {children}
        <Footer />
      </div>
    </>
  );
};

export default Layout;
