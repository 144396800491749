import React from "react";

const NewsLetter = () => {
  return (
    <section className="nft2-newsletter-area pt-60 pb-120 position-relative overflow-hidden">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="title-style-2 text-center newsletter-content-wrapper">
              <h2>Don't miss an update drop!</h2>
              <div className="title-desc">
                <p>
                  Follow Our Social Networks
                </p>
              </div>
              <div className="d-inline-block mt-3 me-3">
                <button
                  style={{
                    width: '150px',
                    height: '45px',
                    backgroundColor: '#0e1217',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s',
                  }}
                  onMouseEnter={(e) => (e.target.style.backgroundColor = '#232930')}
                  onMouseLeave={(e) => (e.target.style.backgroundColor = '#0e1217')}
                  className="btn btn-primary" onClick={() => window.open("https://twitter.com/EvilKongsNFTs", "_blank")}
                >
                  Twitter (X)
                </button>
              </div>
              <div className="d-inline-block mt-3 me-3">
                <button style={{
                  width: '150px',
                  height: '45px',
                  backgroundColor: '#0e1217',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
                  onMouseEnter={(e) => (e.target.style.backgroundColor = '#232930')}
                  onMouseLeave={(e) => (e.target.style.backgroundColor = '#0e1217')}
                  className="btn btn-primary" onClick={() => window.open("https://discord.com/invite/zUsTFf5Tnm", "_blank")}>
                  Discord
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="assets/img/shapes/cta-2-right.png"
        alt="shape"
        className="shape-right-top position-absolute"
      />
    </section>
  );
};

export default NewsLetter;
