import React from "react";
import { Link } from "react-router-dom";
import Layout from "../components/layout/Layout";

const NotFound = () => {
  return (
    <Layout title="404 Not Found">
      <section className="nft-not-found-area overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="not-found-content-wrapper text-center position-relative">
              <img src="assets/img/404.png" className="img-fluid" alt="404" />
              <img
                src="assets/img/oops.svg"
                className="position-absolute oops-txt"
                alt="oops"
              />
              <img
                src="assets/img/404-shape.svg"
                className="position-absolute ntf-shape"
                alt="oops"
              />
              <h2 className="mt-4">Oops! That page can't be found!</h2>
              <p className="mt-3">
                Sorry, but the page you are looking for does not existing
              </p>
              <div className="primary-btn mt-4">
                <Link to="/">Back to Home</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFound;
