import React, { useState, useEffect } from "react";
import { ConnectWallet, lightTheme } from "@thirdweb-dev/react";
import { Link } from "react-router-dom";
import { navDemo, navPagesData } from "../../../utils/data";

export default function Navbar() {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const stickyheader = document.querySelector(".main-header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <header className="main-header position-absolute w-100">
        <nav
          className={`navbar navbar-expand-xl navbar-dark sticky-header ${scroll > headerTop ? "affix" : ""
            }`}
        >
          <div className="container d-flex align-items-center justify-content-lg-between position-relative">
            <Link
              to="/"
              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
            >
              <img src="assets/img/logo.png" alt="logo" className="img-fluid" />
            </Link>

            {/* Botón de hamburguesa para dispositivos móviles */}
            <button
              className="navbar-toggler position-fixed right-0 top-0 border-0"
              style={{ transform: 'translateY(70%)' }} // Alineación centrada
              onClick={toggleMobileMenu}
              aria-label="Toggle navigation"
            >
              <span className="fas fa-bars"></span>
            </button>

            {/* Menú colapsable */}
            <div
              className={`collapse navbar-collapse justify-content-center ${isMobileMenuOpen ? "show" : ""
                } bg-#17191f !important`} // Aplicamos fondo oscuro cuando el menú está abierto
            >
              <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                {/* Lista de navegación */}
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link"
                    to="https://evilkongs.xyz"
                    role="button"
                    aria-expanded="false"
                  >
                    Home
                  </Link>
                  <div className="dropdown-menu border-0 rounded-custom shadow py-0">
                    <div className="dropdown-grid rounded-custom width-half">
                      <div className="dropdown-grid-item">
                        {navDemo.map((data, i) => (
                          <Link
                            to={data.path}
                            className="dropdown-link demo-link"
                            key={i + 1}
                          >
                            <span className="demo-list secondary-bg rounded-circle text-white">
                              {i + 1}
                            </span>
                            <div className="dropdown-info">
                              <div className="drop-title">{data.menuTitle}</div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </li>
                {/* Otros elementos del menú */}
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Hentai NFTs
                  </Link>
                  <div className="dropdown-menu border-0 rounded-custom shadow py-0">
                    <div className="dropdown-grid rounded-custom width-full-3">
                      <div className="dropdown-grid-item first-item">
                        <Link
                          onClick={() => window.open("https://launchpad.heymint.xyz/mint/lilith-mod", "_blank")}
                          className="mega-list-item d-flex align-items-center"
                        >
                          <img
                            src="assets/img/home2/lilith-md.png"
                            alt="collector"
                            className="img-fluid rounded-circle"
                          />
                          <div className="right-content">
                            <h6>Lilith MD</h6>
                            <span>Live - 1.75 POL</span>
                          </div>
                        </Link>
                        <Link
                          onClick={() => window.open("https://launchpad.heymint.xyz/mint/bunny-love", "_blank")}
                          className="mega-list-item d-flex align-items-center"
                        >
                          <img
                            src="assets/img/home2/bunny-love.png"
                            alt="collector"
                            className="img-fluid rounded-circle"
                          />
                          <div className="right-content">
                            <h6>Bunny Love</h6>
                            <span>Live - 1.5 POL</span>
                          </div>
                        </Link>
                        <Link
                          onClick={() => window.open("https://launchpad.heymint.xyz/mint/demon-desire", "_blank")}
                          className="mega-list-item d-flex align-items-center"
                        >
                          <img
                            src="assets/img/home2/demon-desire.png"
                            alt="collector"
                            className="img-fluid rounded-circle"
                          />
                          <div className="right-content">
                            <h6>Demon Desire</h6>
                            <span>Live - 1.5 POL</span>
                          </div>
                        </Link>
                        <Link
                          onClick={() => window.open("https://launchpad.heymint.xyz/mint/elf-waifus", "_blank")}
                          className="mega-list-item d-flex align-items-center"
                        >
                          <img
                            src="assets/img/home2/elf-waifus.png"
                            alt="collector"
                            className="img-fluid rounded-circle"
                          />
                          <div className="right-content">
                            <h6>Elf Waifus</h6>
                            <span>Sold Out</span>
                          </div>
                        </Link>
                      </div>
                      <div className="dropdown-grid-item second-item">
                        <Link
                          onClick={() => window.open("https://launchpad.heymint.xyz/mint/foxy-girls", "_blank")}
                          className="mega-list-item d-flex align-items-center"
                        >
                          <img
                            src="assets/img/home2/foxy-girls.png"
                            alt="collector"
                            className="img-fluid rounded-circle"
                          />
                          <div className="right-content">
                            <h6>Foxy Girls</h6>
                            <span>Sold Out</span>
                          </div>
                        </Link>
                        <Link
                          onClick={() => window.open("https://launchpad.heymint.xyz/mint/celestial-angels", "_blank")}
                          className="mega-list-item d-flex align-items-center"
                        >
                          <img
                            src="assets/img/home2/cean.png"
                            alt="collector"
                            className="img-fluid rounded-circle"
                          />
                          <div className="right-content">
                            <h6>Celestial Angels</h6>
                            <span>Sold Out</span>
                          </div>
                        </Link>
                        <Link
                          onClick={() => window.open("https://launchpad.heymint.xyz/mint/ero-nuns", "_blank")}
                          className="mega-list-item d-flex align-items-center"
                        >
                          <img
                            src="assets/img/home2/ero-nuns.png"
                            alt="collector"
                            className="img-fluid rounded-circle"
                          />
                          <div className="right-content">
                            <h6>Ero Nuns</h6>
                            <span>Sold Out</span>
                          </div>
                        </Link>
                        <Link
                          onClick={() => window.open("https://launchpad.heymint.xyz/mint/submission", "_blank")}
                          className="mega-list-item d-flex align-items-center"
                        >
                          <img
                            src="assets/img/home2/submission.png"
                            alt="collector"
                            className="img-fluid rounded-circle"
                          />
                          <div className="right-content">
                            <h6>Submission</h6>
                            <span>Sold Out</span>
                          </div>
                        </Link>
                      </div>
                      <div className="dropdown-grid-item last-item radius-right-side">
                        <div className="nft3-filter-item-wrapper">
                          <div className="thumb-wrapper">
                            <Link>
                              <img
                                src="assets/img/home2/ek+18.png"
                                alt="nft item"
                                className="img-fluid"
                              />
                            </Link>
                          </div>
                          <div className="item-title">
                            <h2 className="h6">Polygon Network</h2>
                          </div>
                          <div className="item-meta d-flex align-items-center justify-content-between">
                            <span className="amount">
                              Unlock new digital realms
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Staking
                  </Link>
                  <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-dark">
                    <div className="dropdown-grid rounded-custom width-half">
                      <div className="dropdown-grid-item">
                        <Link to="https://arbistake.evilkongs.xyz" className="dropdown-link px-0">
                          <span className="drop-title">Arbitrum</span>
                        </Link>
                        <Link to="https://polystake.evilkongs.xyz" className="dropdown-link px-0">
                          <span className="drop-title">Polygon</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    onClick={() =>
                      window.open("https://x.com/EvilKongsNFTs", "_blank")
                    }
                    role="button"
                    aria-expanded="false"
                  >
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() =>
                      window.open(
                        "https://form.jotform.com/evilkongs/evil-kongs-nft-listing",
                        "_blank"
                      )
                    }
                    className="nav-link"
                  >
                    List your project
                  </Link>
                </li>
              </ul>
            </div>

            <div className={`action-btns d-flex align-items-center text-end me-5 me-xl-0 ${isMobileMenuOpen ? "d-block" : "d-none"} d-sm-flex`}>
              <div className="primary-btn ms-3">
                <ConnectWallet
                  theme={lightTheme({
                    colors: {
                      modalBg: "#1c1f26",
                      borderColor: "transparent",
                      separatorLine: "#282b2f",
                      secondaryText: "#c4c4c4",
                      primaryText: "#ffffff",
                      connectedButtonBg: "transparent",
                      primaryButtonBg: "#1c1f26",
                      primaryButtonText: "#ffffff",
                      secondaryButtonHoverBg: "#282b2f",
                      connectedButtonBgHover: "transparent",
                      walletSelectorButtonHoverBg: "#282b2f",
                      secondaryButtonText: "#ffffff",
                      secondaryButtonBg: "#282b2f",
                    },
                  })}
                  modalTitle={"Evil Kongs"}
                  switchToActiveChain={true}
                  modalSize={"compact"}
                  showThirdwebBranding={false}
                  modalTitleIconUrl={"/assets/img/fabicon.png"}
                />
              </div>
            </div>
          </div>
        </nav>
      </header >
    </>
  );
}
