import React from "react";
import BreadCrumb from "../components/common/BreadCrumb";
import AddressBox from "../components/contact/AddressBox";
import ContactForm from "../components/contact/ContactForm";
import Layout from "../components/layout/Layout";

const Contact = () => {
  return (
    <Layout title="Contact">
      <BreadCrumb
        pageTitle="Contact"
        parentPage="Home"
        prevPage="Pages"
        currentPage="Contact"
      />
      <AddressBox />
      <ContactForm />
    </Layout>
  );
};

export default Contact;
