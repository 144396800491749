import React, { useState, useEffect } from "react";

const EthereumPrice = () => {
  const [price, setPrice] = useState(null);
  const [change, setChange] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEthereumPrice = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd&include_24hr_change=true"
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        const ethPrice = data.ethereum.usd;
        const ethChange = data.ethereum.usd_24h_change;

        setPrice(ethPrice.toFixed(2));
        setChange(ethChange.toFixed(2));
      } catch (err) {
        setError(err.message);
      }
    };

    fetchEthereumPrice();
  }, []);

  return (
    <div>
      {error ? (
        <p style={{ color: "red" }}>Error: {error}</p>
      ) : price ? (
        <p>
          ${price} USD{" "}
          <span
            style={{
              color: change >= 0 ? "green" : "red",
            }}
          >
            ({change >= 0 ? "+" : ""}
            {change}%)
          </span>
        </p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default EthereumPrice;
