import React from "react";
import { Link } from "react-router-dom";

const HeroTwo = () => {
  return (
    <section className="hero-section-two position-relative overflow-hidden pt-80">
      <div className="container-fluid custom-container position-relative overflow-hidden">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6">
              <div className="hero-content-wrap">
                <h1 className="display-4 fw-bold">
                  Evil Staking
                </h1>
                <p>
                  Evil Kongs has launched a staking program on Polygon and Arbitrum, where holders of its collections will earn rewards in $EVIL and $EPEPE. The $EPEPE tokens can be swapped for $GSA, listed on Uniswap, providing both immediate and long-term benefits for participants in the Web3 ecosystem.
                </p>
                <div className="hero-btns">
                  <div className="hero-secondary-btn d-inline-block mt-3 me-3">
                    <a href="#arbitrum">
                      Arbitrum
                    </a>
                  </div>
                  <div className="hero-secondary-btn d-inline-block mt-3">
                    <a href="https://polystake.evilkongs.xyz">
                      Polygon
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="hero-right-img">
                <img
                  src="assets/img/home2/hero-art.png"
                  alt="hero"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroTwo;
