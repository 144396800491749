import React from "react";
import { Link } from "react-router-dom";

const TrendingCollection = () => {
  return (
    <section id="arbitrum" className="nft2-collections">
      <div className="container">
        <div className="text-center">
          <div className="title-style-2 text-center d-flex align-items-center justify-content-center">
            <h2 className="mb-0">Stakeable Collections</h2>
          </div>
          <p>
            On Arbitrum Network
          </p>
        </div>
        <div className="row g-3">
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/evil-kongs" className="d-block">
                <img
                  src="assets/img/home2/ekongs-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/ekongs.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Evil Kongs</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/ahegao-succubus" className="d-block">
                <img
                  src="assets/img/home2/ahegao-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/ahegao-logo.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Ahegao Succubus</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/abyss-lords" className="d-block">
                <img
                  src="assets/img/home2/abyss-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover "
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/abyss-logo.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Abyss Lords</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/evil-heroes" className="d-block">
                <img
                  src="assets/img/home2/ehero-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/eher-logo.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Evil Heroes</h6>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrendingCollection;
