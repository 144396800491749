import React from "react";
import { Link } from "react-router-dom";

const TokenStake = () => {
    return (
        <section id="polygon" className="nft2-collections">
            <div className="container">
                <div className="text-center">
                    <div className="title-style-2 text-center d-flex align-items-center justify-content-center">
                        <h2 className="mb-0">ERC-20 Staking - $EVIL</h2>
                    </div>
                    <p>
                        On Arbitrum Network
                    </p>
                </div>
                <div className="row g-3">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="nft2-collection-column">
                                <Link to="/" className="d-block text-center">
                                    <img
                                        src="assets/img/home2/evil-token-cover.png"
                                        className="img-fluid rounded-top position-relative"
                                        alt="cover"
                                    />
                                    <div className="profile-wrapper">
                                        <img
                                            src="assets/img/home2/evil-token.png"
                                            className="img-fluid"
                                            alt="profile"
                                        />
                                    </div>
                                    <div className="item-bottom">
                                        <h6>Coming Soon</h6>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TokenStake;
