import { useState } from 'react';

const ErrorMessagePopup = ({ message, onClose }) => {
  return (
    <div style={{ position: 'fixed', bottom: '0', left: '0', width: '100%', backgroundColor: '#ff6347', padding: '10px', textAlign: 'center', zIndex: '9999' }}>
      <p style={{ color: '#fff', margin: '0' }}>{message}</p>
      <button onClick={onClose} style={{ backgroundColor: 'transparent', border: 'none', color: '#fff', cursor: 'pointer', marginLeft: '10px' }}>Close</button>
    </div>
  );
};

export default ErrorMessagePopup;