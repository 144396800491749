import React from "react";
import BreadCrumb from "../components/common/BreadCrumb";
import Layout from "../components/layout/Layout";
import FaqTabs from "../components/tabs/FaqTabs";

const Faqs = () => {
  return (
    <Layout title="Faqs">
      <BreadCrumb
        pageTitle="FAQ"
        parentPage="Home"
        prevPage="Pages"
        currentPage="Faq"
      />
      <FaqTabs />
    </Layout>
  );
};

export default Faqs;
