import React from "react";

const ContactForm = () => {
  return (
    <section className="nft-contact-area pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="nft-contact-left text-center">
              <img
                src="assets/img/vr-glass.jpg"
                alt="contact"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="contact-form">
              <div className="title-txt">
                <h2>Get quick response from us</h2>
              </div>
              <div className="nft-form-area">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-field">
                        <input type="text" placeholder="Name" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field">
                        <input type="email" placeholder="Your Mail" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field">
                        <input type="tel" placeholder="Your Phone" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-field">
                        <input type="text" placeholder="Subject" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input-field">
                        <textarea rows="7" placeholder="Description"></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="submit-btn">
                        <button type="submit">Send Now</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
