import React from "react";

const FaqTabs = () => {
  return (
    <section className="nft1-faq-area ptb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="hwit-title text-center">
              <h2>Frequently Asked questions</h2>
              <p>
                You can set preferred display name create your profile URL and
                manage other personal settings customize interactive benefits
                and market positioning.
              </p>
            </div>
          </div>
        </div>
        <div className="faq-tab-wrapper">
          <ul className="nav nav-tabs">
            <li>
              <button
                className="active"
                data-bs-toggle="tab"
                data-bs-target="#general"
              >
                General question
              </button>
            </li>
            <li>
              <button data-bs-toggle="tab" data-bs-target="#support">
                Support Question
              </button>
            </li>
            <li>
              <button data-bs-toggle="tab" data-bs-target="#transaction">
                transaction question
              </button>
            </li>
            <li>
              <button data-bs-toggle="tab" data-bs-target="#fees">
                fees question
              </button>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="general">
              <div className="row">
                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="general_1">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                          >
                            What is an NFT Marketplace?
                          </button>
                        </h6>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#general_1"
                        >
                          <div className="accordion-body">
                            <p>
                              NFTs involving digital art generally do not store
                              the associated artwork file on the blockchain due
                              to its size. The token functions in a link
                              rot.[18] Because NFTs are functionally separate
                              from the underlying artworks, anybody can easily
                              save a copy of an NFT's image, popularly through a
                              right click.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsetwo"
                          >
                            Customer Support is Avaialbe?
                          </button>
                        </h6>
                        <div
                          id="collapsetwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general_1"
                        >
                          <div className="accordion-body">
                            <p>
                              NFTs involving digital art generally do not store
                              the associated artwork file on the blockchain due
                              to its size. The token functions in a link
                              rot.[18] Because NFTs are functionally separate
                              from the underlying artworks, anybody can easily
                              save a copy of an NFT's image, popularly through a
                              right click.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsethree"
                          >
                            What happens when I receive an order?
                          </button>
                        </h6>
                        <div
                          id="collapsethree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general_1"
                        >
                          <div className="accordion-body">
                            <p>
                              NFTs involving digital art generally do not store
                              the associated artwork file on the blockchain due
                              to its size. The token functions in a link
                              rot.[18] Because NFTs are functionally separate
                              from the underlying artworks, anybody can easily
                              save a copy of an NFT's image, popularly through a
                              right click.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsefour"
                          >
                            How do i find my transaction neftron
                          </button>
                        </h6>
                        <div
                          id="collapsefour"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general_1"
                        >
                          <div className="accordion-body">
                            <p>
                              NFTs involving digital art generally do not store
                              the associated artwork file on the blockchain due
                              to its size. The token functions in a link
                              rot.[18] Because NFTs are functionally separate
                              from the underlying artworks, anybody can easily
                              save a copy of an NFT's image, popularly through a
                              right click.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="general_2">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsefive"
                          >
                            What is an NFT Marketplace?
                          </button>
                        </h6>
                        <div
                          id="collapsefive"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#general_2"
                        >
                          <div className="accordion-body">
                            <p>
                              NFTs involving digital art generally do not store
                              the associated artwork file on the blockchain due
                              to its size. The token functions in a link
                              rot.[18] Because NFTs are functionally separate
                              from the underlying artworks, anybody can easily
                              save a copy of an NFT's image, popularly through a
                              right click.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsesix"
                          >
                            What happens when I receive an order?
                          </button>
                        </h6>
                        <div
                          id="collapsesix"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general_2"
                        >
                          <div className="accordion-body">
                            <p>
                              NFTs involving digital art generally do not store
                              the associated artwork file on the blockchain due
                              to its size. The token functions in a link
                              rot.[18] Because NFTs are functionally separate
                              from the underlying artworks, anybody can easily
                              save a copy of an NFT's image, popularly through a
                              right click.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseseven"
                          >
                            How do I fine my transaction neftron
                          </button>
                        </h6>
                        <div
                          id="collapseseven"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general_2"
                        >
                          <div className="accordion-body">
                            <p>
                              NFTs involving digital art generally do not store
                              the associated artwork file on the blockchain due
                              to its size. The token functions in a link
                              rot.[18] Because NFTs are functionally separate
                              from the underlying artworks, anybody can easily
                              save a copy of an NFT's image, popularly through a
                              right click.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseeight"
                          >
                            Customer support is available?
                          </button>
                        </h6>
                        <div
                          id="collapseeight"
                          className="accordion-collapse collapse"
                          data-bs-parent="#general_2"
                        >
                          <div className="accordion-body">
                            <p>
                              NFTs involving digital art generally do not store
                              the associated artwork file on the blockchain due
                              to its size. The token functions in a link
                              rot.[18] Because NFTs are functionally separate
                              from the underlying artworks, anybody can easily
                              save a copy of an NFT's image, popularly through a
                              right click.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="support">
              <div className="row">
                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="support_1">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTherteen"
                          >
                            What is an NFT Marketplace?
                          </button>
                        </h6>
                        <div
                          id="collapseTherteen"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#support_1"
                        >
                          <div className="accordion-body">
                            <p>
                              NFTs involving digital art generally do not store
                              the associated artwork file on the blockchain due
                              to its size. The token functions in a link
                              rot.[18] Because NFTs are functionally separate
                              from the underlying artworks, anybody can easily
                              save a copy of an NFT's image, popularly through a
                              right click.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="support_2">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourteen"
                          >
                            What is an NFT Marketplace?
                          </button>
                        </h6>
                        <div
                          id="collapseFourteen"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#support_2"
                        >
                          <div className="accordion-body">
                            <p>
                              NFTs involving digital art generally do not store
                              the associated artwork file on the blockchain due
                              to its size. The token functions in a link
                              rot.[18] Because NFTs are functionally separate
                              from the underlying artworks, anybody can easily
                              save a copy of an NFT's image, popularly through a
                              right click.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="transaction">
              <div className="row">
                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="transaction_1">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTherteen"
                          >
                            What is an NFT Marketplace?
                          </button>
                        </h6>
                        <div
                          id="collapseTherteen"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#transaction_1"
                        >
                          <div className="accordion-body">
                            <p>
                              NFTs involving digital art generally do not store
                              the associated artwork file on the blockchain due
                              to its size. The token functions in a link
                              rot.[18] Because NFTs are functionally separate
                              from the underlying artworks, anybody can easily
                              save a copy of an NFT's image, popularly through a
                              right click.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="transaction_2">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourteen"
                          >
                            What is an NFT Marketplace?
                          </button>
                        </h6>
                        <div
                          id="collapseFourteen"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#transaction_2"
                        >
                          <div className="accordion-body">
                            <p>
                              NFTs involving digital art generally do not store
                              the associated artwork file on the blockchain due
                              to its size. The token functions in a link
                              rot.[18] Because NFTs are functionally separate
                              from the underlying artworks, anybody can easily
                              save a copy of an NFT's image, popularly through a
                              right click.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="fees">
              <div className="row">
                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="fees_1">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsTweenty"
                          >
                            What is an NFT Marketplace?
                          </button>
                        </h6>
                        <div
                          id="collapsTweenty"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#fees_1"
                        >
                          <div className="accordion-body">
                            <p>
                              NFTs involving digital art generally do not store
                              the associated artwork file on the blockchain due
                              to its size. The token functions in a link
                              rot.[18] Because NFTs are functionally separate
                              from the underlying artworks, anybody can easily
                              save a copy of an NFT's image, popularly through a
                              right click.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFourty"
                          >
                            Customer Support is Avaialbe?
                          </button>
                        </h6>
                        <div
                          id="collapseFourty"
                          className="accordion-collapse collapse"
                          data-bs-parent="#fees_1"
                        >
                          <div className="accordion-body">
                            <p>
                              NFTs involving digital art generally do not store
                              the associated artwork file on the blockchain due
                              to its size. The token functions in a link
                              rot.[18] Because NFTs are functionally separate
                              from the underlying artworks, anybody can easily
                              save a copy of an NFT's image, popularly through a
                              right click.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="accordion-wrapper">
                    <div className="accordion" id="fees_2">
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThirty"
                          >
                            What is an NFT Marketplace?
                          </button>
                        </h6>
                        <div
                          id="collapseThirty"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#fees_2"
                        >
                          <div className="accordion-body">
                            <p>
                              NFTs involving digital art generally do not store
                              the associated artwork file on the blockchain due
                              to its size. The token functions in a link
                              rot.[18] Because NFTs are functionally separate
                              from the underlying artworks, anybody can easily
                              save a copy of an NFT's image, popularly through a
                              right click.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThirtyTwo"
                          >
                            What happens when I receive an order?
                          </button>
                        </h6>
                        <div
                          id="collapseThirtyTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#fees_2"
                        >
                          <div className="accordion-body">
                            <p>
                              NFTs involving digital art generally do not store
                              the associated artwork file on the blockchain due
                              to its size. The token functions in a link
                              rot.[18] Because NFTs are functionally separate
                              from the underlying artworks, anybody can easily
                              save a copy of an NFT's image, popularly through a
                              right click.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqTabs;
