import React from "react";
import { Link } from "react-router-dom";
import BitcoinPrice from "../currenciesprices/bitcoin";
import EthereumPrice from "../currenciesprices/ethereum";
import PolygonPrice from "../currenciesprices/polygon";

export default function VideoBlog() {
  return (
    <section className="nft2-video-blog overflow-hidden position-relative ptb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="title-top">
              <h2>Ethereum & Polygon News</h2>
            </div>
          </div>
        </div>
        <div className="video-blog-wrapper">
          <div className="row g-4">
            <div className="col-xl-4 col-md-6">
              <div className="nft2-vblog-column">
                <div className="thumb-wrapper">
                  <img
                    src="assets/img/home2/new-eth1.png"
                    className="img-fluid"
                    alt="feature"
                  />
                </div>
                <div className="blog-content">
                  <div className="blog-content-wrapper">
                    <div className="blog-title">
                      <Link onClick={() => window.open("https://coinmarketcap.com/community/articles/67599992ae05e53830071a82/", "_blank")} style={{ cursor: "pointer" }}>
                        <h6>Ethereum - CoinmarketCap</h6>
                      </Link>
                    </div>
                    <div className="blog-authors">
                      <div className="authors">
                        <div className="nft2-explore-creator">
                          <div className="numbers">
                            <span>ETH/BTC Hits Key Trendline as Altcoins Face Flash Crash.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="nft2-vblog-column">
                <div className="thumb-wrapper">
                  <img
                    src="assets/img/home2/new-pol1.png"
                    className="img-fluid"
                    alt="feature"
                  />
                </div>
                <div className="blog-content">
                  <div className="blog-content-wrapper">
                    <div className="blog-title">
                      <Link onClick={() => window.open("https://coinmarketcap.com/community/articles/6753799974d8cc74bdef1b47/", "_blank")} style={{ cursor: "pointer" }}>
                        <h6>Polygon - CoinmarketCap</h6>
                      </Link>
                    </div>
                    <div className="blog-authors">
                      <div className="authors">
                        <div className="nft2-explore-creator">
                          <div className="numbers">
                            <span>SEC Claims Secondary Trading of BNB and These 10 Tokens Qualifies as Securities Under Howey Test.</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="row g-4 nft2-audion-blog">
                <div className="col-xl-12 col-md-6">
                  <div className="audio-blog-item">
                    <div className="thumb-wrapper">
                      <img
                        src="assets/img/home2/btc.png"
                        className="img-fluid"
                        alt="feature"
                      />
                    </div>
                    <div className="ad-blog-content">
                      <div className="content-left">
                        <div className="blog-title">
                          <h6>Bitcoin</h6>
                        </div>
                        <div className="content-bottom">
                          <div className="bid-amount">
                            <span>Price</span>
                            <h6><BitcoinPrice/></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-md-6">
                  <div className="audio-blog-item">
                    <div className="thumb-wrapper">
                      <img
                        src="assets/img/home2/eth.png"
                        className="img-fluid"
                        alt="feature image"
                      />
                    </div>
                    <div className="ad-blog-content">
                      <div className="content-left">
                        <div className="blog-title">
                          <h6>Ethereum</h6>
                        </div>
                        <div className="content-bottom">
                          <div className="bid-amount">
                            <span>USD Price</span>
                            <h6><EthereumPrice/></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-md-6">
                  <div className="audio-blog-item">
                    <div className="thumb-wrapper">
                      <img
                        src="assets/img/home2/pol.png"
                        className="img-fluid"
                        alt="feature"
                      />
                    </div>
                    <div className="ad-blog-content">
                      <div className="content-left">
                        <div className="blog-title">
                          <h6>Polygon</h6>
                        </div>
                        <div className="content-bottom">
                          <div className="bid-amount">
                            <span>USD Price</span>
                            <h6><PolygonPrice/></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const counter = ({ hours, minutes, seconds }) => {
  return (
    <ul className="downcount-timer">
      <li>
        <span className="hours">{hours}</span>
        <span>h</span>
      </li>
      <li className="seperator px-1">:</li>
      <li className="pe-1">
        <span className="minutes">{minutes}</span>
        <span>m</span>
      </li>
      <li className="seperator px-1">:</li>
      <li className="pe-1">
        <span className="seconds">{seconds}</span>
        <span>s</span>
      </li>
    </ul>
  );
};
