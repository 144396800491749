import React from "react";

const Footer = () => {
  return (
    <footer className="footer-style-3">
      <div className="footer-top position-relative overflow-hidden pt-100 pb-0 pb-sm-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="nft3-footer-widget">
                <div className="footer-title">
                  <img src="assets/img/home2/evilkongs.png" style={{ width: "50%" }} />
                </div>
                <p style={{ textAlign: "justify" }}>Evil Kongs is an exciting bran that has broken into the world of NFTs with a unique, wicked and lust approach. Designing NFTs with a dark Edge, Evil Kongs has captured the attention of crypto community and cryptocurrency enthusiasts.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="nft3-footer-widget">
                <div className="footer-title">
                  <img className="shape-right-top2 position-absolute" src="assets/img/home2/AHSU.png" style={{ width: "60%" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="copyright-left">
                  <p>Copyright by Evil Kongs</p>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="copyright-right">
                  <p>All Rights Reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
